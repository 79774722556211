import React, { useMemo } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { Button, Grid } from '@material-ui/core';
import { Formik, FastField } from 'formik';
import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';

import FormAutocomplete from '../../components/FormAutocomplete';
import useStyles from './styles';

const ExportWorkingHoursModal = ({ isOpen, onClose, onExport, dialogProps }) => {
  const classes = useStyles();

  const yearOptions = useMemo(
    () => [{ label: 'All', value: '' }].concat(
      [...Array(new Date().getFullYear() + 1).keys()]
        .slice(2023)
        .reverse()
        .map(item => ({ label: item.toString(), value: item }))
    ),
    []
  );

  const initialValues = useMemo(() => ({ year: '' }), []);

  const handleExportWorkingHours = values => {
    onClose();
    onExport(values.year);
  };

  return (
    <Dialog onClose={onClose} className={classes.container} open={isOpen} maxWidth="xs" fullWidth {...dialogProps}>
      <DialogTitle className={classes.title}>Export Working Hours</DialogTitle>
      {onClose && (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
      <DialogContent className={classes.content}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleExportWorkingHours}
          validateOnChange={false}
        >
          {({ submitForm }) => (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FastField
                  label="Year"
                  name="year"
                  options={yearOptions}
                  disableClearable={true}
                  component={FormAutocomplete}
                />
              </Grid>
              <Grid item xs={6}>
                <Grid container justify="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={submitForm}
                    startIcon={<GetAppIcon />}
                  >
                    Export
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ExportWorkingHoursModal;
