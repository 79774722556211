import * as Yup from 'yup';

import { checkMinDate, checkMaxDate, dateCheck, compareNumbers } from './method';
import { getLastRequiredYearsList } from '../constants/yearHelper';
import { fileMaxSize } from '../constants/fileSizes';
import { enums } from '../constants/formConstats';
import messages from './errorMessages';
import BLSIndustry from '../constants/BLSIndustry';

const passwordRegex = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})';

const urlRegex = '^(http[s]?:\\/\\/){0,1}(www\\.){0,1}[a-zA-Z0-9\\.\\-]+\\.[a-zA-Z]{2,5}[\\.]{0,1}$';

const getPhoneNumberTest = (name, isRequired) => ({
  name: 'phoneNumberTest',
  message: messages.phoneIncorrect,
  test: function (value) {
    return !value || (!isRequired && (value.length === 0 || value.replace(/\D/g, '') === sessionStorage.getItem(`${name}_countryDataDialCode_`))) || value.length === sessionStorage.getItem(`${name}_countryDataFormat_`)?.length;
  },
});

Yup.addMethod(Yup.string, 'compareNumbers', compareNumbers);
Yup.addMethod(Yup.mixed, 'dateCheck', dateCheck);
Yup.addMethod(Yup.mixed, 'maxDate', checkMaxDate);
Yup.addMethod(Yup.mixed, 'minDate', checkMinDate);

export const signInSchema = Yup.object().shape({
  email: Yup.string()
    .email(messages.email)
    .required(messages.required),
  password: Yup.string().required(messages.required),
});

export const resetPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email(messages.email)
    .required(messages.required),
});

export const setNewPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, messages.incorrectPassword)
    .max(50, messages.incorrectPassword)
    .matches(passwordRegex, messages.incorrectPassword)
    .required(messages.required),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], messages.confirmPassword)
    .min(8, messages.incorrectPassword)
    .max(50, messages.incorrectPassword)
    .matches(passwordRegex, messages.incorrectPassword)
    .required(messages.required),
});

export const createCompanySchema = Yup.object().shape({
  type: Yup.string().required(messages.required),
  companyName: Yup.string()
    .max(100, messages.requiredCompanyNameMax100)
    .trim(messages.required)
    .required(messages.required),
  // phoneNumber: Yup.string().when({
  //   is: value => !(value && value.match(/\d+/g)),
  //   then: shema => shema.notOneOf(phoneCases, messages.required),
  //   otherwise: shema => shema.matches(phoneRegex, messages.phoneIncorrect),
  // }),
  phoneNumber: Yup.string().required(messages.required)
    .test(getPhoneNumberTest('phoneNumber', true)),
  // fax: Yup.string().when({
  //   is: value => value && value.match(/\d+/g),
  //   then: shema => shema.matches(phoneRegex, messages.faxIncorrect),
  // }),
  emailAddress: Yup.string()
    .max(255, messages.requiredUserEmailMax255)
    .email(messages.email),
  website: Yup.string()
    .max(255, messages.requiredUrlMax255)
    .matches(urlRegex, messages.urlIncorrect),
  taxId: Yup.string()
    .length(9, messages.requiredTaxIdNineDigits)
    .required(messages.required),
});

export const officersSchema = Yup.object().shape({
  ceo: Yup.object().shape({
    name: Yup.string()
      .min(3, messages.requiredNameMin3)
      .max(255, messages.requiredNameMax255),
    emailAddress: Yup.string()
      .max(255, messages.requiredUserEmailMax255)
      .email(messages.email),
    dateHired: Yup.mixed()
      .dateCheck(messages.invalidDate)
      .maxDate(new Date(), messages.maxDate)
      .nullable(),
  }),
  president: Yup.lazy(value => {
    if (value.isPositionExist === enums.yesno.Yes) {
      return Yup.object().shape({
        name: Yup.string()
          .min(3, messages.requiredNameMin3)
          .max(255, messages.requiredNameMax255)
          .required(messages.required),
        emailAddress: Yup.string()
          .max(255, messages.requiredUserEmailMax255)
          .email(messages.email)
          .required(messages.required),
        dateHired: Yup.mixed()
          .dateCheck(messages.invalidDate)
          .maxDate(new Date(), messages.maxDate)
          .nullable()
          .required(messages.required),
      });
    }
    return Yup.mixed().notRequired();
  }),
  vicePresident: Yup.lazy(value => {
    if (value.isPositionExist === enums.yesno.Yes) {
      return Yup.object().shape({
        name: Yup.string()
          .min(3, messages.requiredNameMin3)
          .max(255, messages.requiredNameMax255)
          .required(messages.required),
        emailAddress: Yup.string()
          .max(255, messages.requiredUserEmailMax255)
          .email(messages.email)
          .required(messages.required),
        dateHired: Yup.mixed()
          .dateCheck(messages.invalidDate)
          .maxDate(new Date(), messages.maxDate)
          .nullable()
          .required(messages.required),
      });
    }
    return Yup.mixed().notRequired();
  }),
  treasurer: Yup.lazy(value => {
    if (value.isPositionExist === enums.yesno.Yes) {
      return Yup.object().shape({
        name: Yup.string()
          .min(3, messages.requiredNameMin3)
          .max(255, messages.requiredNameMax255),
        emailAddress: Yup.string()
          .max(255, messages.requiredUserEmailMax255)
          .email(messages.email),
        dateHired: Yup.mixed()
          .dateCheck(messages.invalidDate)
          .maxDate(new Date(), messages.maxDate)
          .nullable()
          .required(messages.required),
      });
    }
    return Yup.mixed().notRequired();
  }),
  bidManager: Yup.object().shape({
    firstName: Yup.string()
      .min(3, messages.requiredFirstNameMin3)
      .max(255, messages.requiredNameMax255),
    lastName: Yup.string()
      .min(3, messages.requiredLastNameMin3)
      .max(255, messages.requiredNameMax255),
    title: Yup.string().max(100, messages.requiredTitleMax100),
    email: Yup.string()
      .max(255, messages.requiredUserEmailMax255)
      .email(messages.email),
    // fax: Yup.string().when({
    //   is: value => !(value && value.match(/\d+/g)),
    //   then: shema => shema.notRequired(),
    //   otherwise: shema => shema.matches(phoneRegex, messages.phoneIncorrect),
    // }),
    phone: Yup.string()
      .test(getPhoneNumberTest('bidManager.phone', false)),
  }),
});

export const parentCompanySchema = Yup.object().shape({
  isParentCompanyExist: Yup.string().required(),
  isSubsidiaryExist: Yup.string().required(),
  name: Yup.string()
    .max(255, messages.requiredParentCompanyNameMax255)
    .when('isParentCompanyExist', {
      is: isParentCompanyExist => isParentCompanyExist === 'Yes',
      then: shema => shema.required(messages.required),
    }),
  phoneNumber: Yup.string()
    .test(getPhoneNumberTest('phoneNumber', false))
    .when('isParentCompanyExist', {
      is: isParentCompanyExist => isParentCompanyExist === 'Yes',
      then: shema => shema.required(messages.required)
        .test(getPhoneNumberTest('phoneNumber', true)),
    }),
  streetAddress: Yup.object().when('isParentCompanyExist', {
    is: isParentCompanyExist => isParentCompanyExist === 'Yes',
    then: Yup.object().shape({
      street: Yup.string()
        .min(3, messages.requiredAddressMin3)
        .max(255, messages.requiredStreetNameMax255),
      city: Yup.string()
        .min(3, messages.requiredCityMin3)
        .max(255, messages.requiredCityMax255),
      state: Yup.string(),
      zip: Yup.string().min(5, messages.requiredZipMin5),
    }),
  }),
});

export const subsidiaryTableSchema = tableData =>
  Yup.object().shape({
    name: Yup.string()
      .required(messages.required)
      .max(255, messages.requiredNameMax255)
      .when({
        is: () => tableData && !tableData.length,
        then: schema => schema.required(messages.subsidiaryCount),
      }),
  });

export const insurancePolicySchema = Yup.object().shape({
  insurancePolicyContactInfo: Yup.object().shape({
    firstName: Yup.string()
      .min(3, messages.requiredFirstNameMin3)
      .max(100, messages.requiredUserFirstNameMax100),
    lastName: Yup.string()
      .min(3, messages.requiredLastNameMin3)
      .max(100, messages.requiredUserSurnameMax100),
    title: Yup.string().max(100, messages.requiredTitleMax100),
    phoneNumber: Yup.string()
      .test(getPhoneNumberTest('insurancePolicyContactInfo.phoneNumber', false)),
    // fax: Yup.string().when({
    //   is: value => !(value && value.match(/\d+/g)),
    //   then: shema => shema.notRequired(),
    //   otherwise: shema => shema.matches(phoneRegex, messages.faxIncorrect),
    // }),
  }),
  generalLiability: Yup.lazy(value => {
    if (value !== undefined) {
      return Yup.object().shape({
        name: Yup.string()
          .min(3, messages.requiredNameMin3)
          .max(255, messages.requiredNameMax255),
        expirationDate: Yup.mixed()
          .dateCheck(messages.invalidDate)
          .nullable()
          .when('name', {
            is: value => value !== undefined,
            then: shema => shema.required(messages.required),
            otherwise: shema => shema.notRequired(),
          }),
        amount: Yup.mixed().when('name', {
          is: value => value !== undefined,
          then: shema => shema.required(messages.required),
          otherwise: shema => shema.notRequired(),
        }),
      });
    }
    return Yup.mixed().notRequired();
  }),
  workersCompensation: Yup.object().shape({
    name: Yup.string().when('isSelfInsured', {
      is: value => value !== enums.yesno.Yes,
      then: shema =>
        shema
          .min(3, messages.requiredNameMin3)
          .max(255, messages.requiredNameMax255)
          .required(messages.required),
      otherwise: shema => shema.notRequired(),
    }),
    expirationDate: Yup.mixed()
      .dateCheck(messages.invalidDate)
      .nullable()
      .when('isSelfInsured', {
        is: value => value !== enums.yesno.Yes,
        then: shema => shema.required(messages.required),
        otherwise: shema => shema.notRequired(),
      }),
    amount: Yup.mixed().when('isSelfInsured', {
      is: value => value !== enums.yesno.Yes,
      then: shema => shema.required(messages.required),
      otherwise: shema => shema.notRequired(),
    }),
  }),
  vehicle: Yup.object().shape({
    vehicleInsuranceType: Yup.string().when('hasVehicleInsurance', {
      is: value => value !== enums.yesno.No,
      then: shema => shema.required(messages.required),
      otherwise: shema => shema.notRequired(),
    }),
    name: Yup.string().when('hasVehicleInsurance', {
      is: value => value !== enums.yesno.No,
      then: shema =>
        shema
          .min(3, messages.requiredNameMin3)
          .max(255, messages.requiredNameMax255)
          .required(messages.required),
      otherwise: shema => shema.notRequired(),
    }),
    expirationDate: Yup.mixed()
      .dateCheck(messages.invalidDate)
      .nullable()
      .when('hasVehicleInsurance', {
        is: value => value !== enums.yesno.No,
        then: shema => shema.required(messages.required),
        otherwise: shema => shema.notRequired(),
      }),
    amount: Yup.mixed().when('hasVehicleInsurance', {
      is: value => value !== enums.yesno.No,
      then: shema => shema.required(messages.required),
      otherwise: shema => shema.notRequired(),
    }),
  }),
  autoinsur: Yup.object().test({
    name: 'autoinsurTest',
    message: messages.required,
    test: function (value) {
      if (this.parent.vehicle.hasVehicleInsurance === enums.yesno.No) return true;
      return value.count > 0;
    },
  }),
});

export const financialInfoSchema = Yup.object().shape({
  minProjectSize: Yup.string().compareNumbers(Yup.ref('maxProjectSize'), messages.minProjectSizeComparison, 'less'),
  maxProjectSize: Yup.string().compareNumbers(Yup.ref('minProjectSize'), messages.maxProjectSizeComparison, 'greater'),
  bankReference: Yup.string().max(100, messages.requiredBankReferencesMax100),
  dunsNumber: Yup.string().length(9, messages.requiredNumberNineDigits),
  finRating: Yup.string().max(4, messages.requiredFinRatingMax4),
});

export const saveCompanyInfo = Yup.object().shape({
  type: Yup.string().required(messages.required),
  companyName: Yup.string()
    .max(100, messages.requiredCompanyNameMax100)
    .required(messages.required),
  // fax: Yup.string().when({
  //   is: value => value && value.match(/\d+/g),
  //   then: shema => shema.matches(phoneRegex, messages.faxIncorrect).required(messages.required),
  // }),
  yearCreated: Yup.mixed()
    .dateCheck(messages.invalidDate)
    .maxDate(new Date(), messages.maxDate)
    .nullable(),
  webSite: Yup.string()
    .max(255, messages.requiredUrlMax255)
    .matches(urlRegex, messages.urlIncorrect),
  taxId: Yup.string()
    .length(9, messages.requiredTaxIdNineDigits)
    .required(messages.required),
  streetAddress: Yup.object().shape({
    street: Yup.string()
      .min(3, messages.requiredAddressMin3)
      .max(255, messages.requiredStreetNameMax255),
    city: Yup.string()
      .min(3, messages.requiredCityMin3)
      .max(255, messages.requiredCityMax255),
    state: Yup.string(),
    zip: Yup.string().min(5, messages.requiredZipMin5),
  }),
  mailingAddress: Yup.object().shape({
    street: Yup.string()
      .min(3, messages.requiredAddressMin3)
      .max(255, messages.requiredStreetNameMax255),
    city: Yup.string()
      .min(3, messages.requiredCityMin3)
      .max(255, messages.requiredCityMax255),
    state: Yup.string(),
    zip: Yup.string().min(5, messages.requiredZipMin5),
  }),
  stateContractorNumber: Yup.string().max(255, messages.requiredStateContractorNumberMax255),
  contactPersonFirstName: Yup.string()
    .trim()
    .min(3, messages.requiredFirstNameMin3)
    .max(100, messages.requiredUserFirstNameMax100),
  contactPersonLastName: Yup.string()
    .trim()
    .min(3, messages.requiredLastNameMin3)
    .max(100, messages.requiredUserLastNameMax100),
  emailAddress: Yup.string()
    .max(255, messages.requiredUserEmailMax255)
    .email(messages.email),
  phoneNumber: Yup.string().required(messages.required)
    .test(getPhoneNumberTest('phoneNumber', true)),
  localContactPersonFirstName: Yup.string()
    .trim()
    .min(3, messages.requiredFirstNameMin3)
    .max(100, messages.requiredUserFirstNameMax100),
  localContactPersonLastName: Yup.string()
    .trim()
    .min(3, messages.requiredLastNameMin3)
    .max(100, messages.requiredUserLastNameMax100),
  localEmailAddress: Yup.string()
    .max(255, messages.requiredUserEmailMax255)
    .email(messages.email),
  localPhoneNumber: Yup.string()
    .test(getPhoneNumberTest('localPhoneNumber', false)),
  description: Yup.string().min(10, messages.requiredDescriptionMin10),
});

export const createOrganizationInfoSchema = Yup.object().shape({
  incorporationState: Yup.string(),
  formOfBusiness: Yup.string(),
  percentMinorityOwned: Yup.number(),
  eeoCategory: Yup.string(),
  incorporationDate: Yup.mixed()
    .dateCheck(messages.invalidDate)
    .nullable(),
});

export const orgInfoTableSchema = Yup.object().shape({
  year: Yup.string().required(messages.required),
  amount: Yup.string().required(messages.required),
});

export const createUserFormSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .min(3, messages.requiredFirstNameMin3)
    .max(100, messages.requiredUserFirstNameMax100)
    .required(messages.required),
  lastName: Yup.string()
    .trim()
    .min(3, messages.requiredLastNameMin3)
    .max(100, messages.requiredUserLastNameMax100)
    .required(messages.required),
  email: Yup.string()
    .max(255, messages.requiredUserEmailMax255)
    .email(messages.email)
    .required(messages.required),
  phoneNumber: Yup.string()
    .test(getPhoneNumberTest('phoneNumber', false)),
});

export const editUserFormSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim()
    .min(3, messages.requiredFirstNameMin3)
    .max(100, messages.requiredUserFirstNameMax100)
    .required(messages.required),
  lastName: Yup.string()
    .trim()
    .min(3, messages.requiredLastNameMin3)
    .max(100, messages.requiredUserLastNameMax100)
    .required(messages.required),
  phoneNumber: Yup.string()
    .test(getPhoneNumberTest('phoneNumber', false)),
});

export const referencesFormSchema = Yup.object().shape({
  customerName: Yup.string()
    .min(3, messages.requiredCustomerNameMin3)
    .max(255, messages.requiredCustomerNameMax255)
    .required(messages.required),
  typeOfWork: Yup.string().max(255, messages.requiredTypeOfWorkMax255),
  jobSize: Yup.string(),
  contactInfo: Yup.object().shape({
    contactPersonFirstName: Yup.string()
      .trim()
      .min(3, messages.requiredFirstNameMin3)
      .max(100, messages.requiredUserFirstNameMax100),
    contactPersonLastName: Yup.string()
      .trim()
      .min(3, messages.requiredLastNameMin3)
      .max(100, messages.requiredUserSurnameMax100),
    phoneNumber: Yup.string()
      .test(getPhoneNumberTest('contactInfo.phoneNumber', false)),
    streetAddress: Yup.object().shape({
      city: Yup.string()
        .min(3, messages.requiredCityMin3)
        .max(255, messages.requiredCityMax255),
      state: Yup.string().nullable(),
      zip: Yup.string().min(5, messages.requiredZipMin5),
    }),
  }),
});

export const environmentalSchema = Yup.object().shape({
  certifications: Yup.string().max(255, messages.requiredCertificationsMax255),
  reportsToName: Yup.string().max(201, messages.requiredReportsToMax201),
  reportsToTitle: Yup.string().max(100, messages.requiredTitleMax100),
  contactInfo: Yup.object().shape({
    contactPersonFirstName: Yup.string()
      .trim()
      .min(3, messages.requiredFirstNameMin3)
      .max(100, messages.requiredUserFirstNameMax100),
    contactPersonLastName: Yup.string()
      .trim()
      .min(3, messages.requiredLastNameMin3)
      .max(100, messages.requiredUserSurnameMax100),
    contactPersonTitle: Yup.string().max(100, messages.requiredTitleMax100),
    phoneNumber: Yup.string()
      .test(getPhoneNumberTest('contactInfo.phoneNumber', false)),
    emailAddress: Yup.string()
      .max(255, messages.requiredUserEmailMax255)
      .email(messages.email),
  }),
});

export const largestJobForm = Yup.object().shape({
  customerName: Yup.string()
    .min(3, messages.requiredCustomerNameMin3)
    .max(255, messages.requiredCustomerNameMax255),
  description: Yup.string()
    .min(10, messages.requiredDescriptionMin10)
    .max(255, messages.requiredDescriptionMax255),
});

export const emrTableSchema = Yup.object().shape({
  year: Yup.string().required(messages.required),
  amount: Yup.string().required(messages.required),
});

export const finInfoTableSchema = Yup.object().shape({
  year: Yup.string().required(messages.required),
  amount: Yup.string().required(messages.required),
});

export const addendumOwnerCreate = Yup.object().shape({
  name: Yup.string()
    .max(255, messages.requiredNameMax255)
    .required(messages.required),
  fileStack: Yup.array()
    .of(
      Yup.object().shape({
        size: Yup.number().max(fileMaxSize, messages.file.bigFileSize),
      }),
    )
    .max(1, messages.file.evenFile)
    .required(messages.required),
});

export const filterCompanyListShema = Yup.object().shape({
  companyName: Yup.string()
    .max(100, messages.requiredCompanyNameMax100)
    .min(3, messages.requiredCompanyNameMin3),
  grades: Yup.string(),
  aemin: Yup.string().compareNumbers(Yup.ref('aemax'), messages.requiredMinLessThanMax, 'less'),
  aemax: Yup.string().compareNumbers(Yup.ref('aemin'), messages.requiredMaxMoreThanMin, 'greater'),
  lcmin: Yup.string().compareNumbers(Yup.ref('lcmax'), messages.requiredMinLessThanMax, 'less'),
  lcmax: Yup.string().compareNumbers(Yup.ref('lcmin'), messages.requiredMaxMoreThanMin, 'greater'),
  bcmin: Yup.string().compareNumbers(Yup.ref('bcmax'), messages.requiredMinLessThanMax, 'less'),
  bcmax: Yup.string().compareNumbers(Yup.ref('bcmin'), messages.requiredMaxMoreThanMin, 'greater'),
});

export const filterArchiveCompanySchema = Yup.object().shape({
  companyName: Yup.string()
    .max(100, messages.requiredCompanyNameMax100)
    .min(3, messages.requiredCompanyNameMin3),
});

export const filterArchiveUserSchema = Yup.object().shape({
  userName: Yup.string()
    .max(100, messages.requiredUserFirstNameMax100)
    .min(3, messages.requiredFirstNameMin3),
});

const injuryValidateRow = Yup.object().shape({
  year: Yup.number().required(messages.required),
  totalHours: Yup.string().required(messages.required),
  fatalities: Yup.number()
    .max(999999, messages.maxNumberValue)
    .required(messages.required),
  lostWorkdayRestricted: Yup.number()
    .max(999999, messages.maxNumberValue)
    .required(messages.required),
  lostWorkdayAway: Yup.number()
    .max(999999, messages.maxNumberValue)
    .required(messages.required),
  injuriesAndIllnessMedicalOnly: Yup.number()
    .max(999999, messages.maxNumberValue)
    .required(messages.required),
  totalOsha: Yup.number()
    .max(999999, messages.maxNumberValue)
    .required(messages.required),
  totalDaysAwayFromWork: Yup.number()
    .max(999999, messages.maxNumberValue)
    .required(messages.required),
});

const lastYearsList = getLastRequiredYearsList().slice(0, 3);
export const injuryValidateSchema = Yup.object().shape({
  years: Yup.array()
    .of(injuryValidateRow)
    //.min(3, messages.minCreatedYears)
    .test('years', messages.cascadeYearList, value => {
      const listSelectedYears = value.map(year => parseInt(year.year));
      return value.length === 0 || lastYearsList.reduce((acc, item) => acc && listSelectedYears.includes(item), true);
    }),
});

const craftsmanValidateRow = Yup.object().shape({
  specialty: Yup.string().max(100, messages.requiredCraftsmanMax100),
  other: Yup.string().max(100, messages.requiredCraftsmanMax100),
});

export const craftsmanValidateSchema = Yup.object({
  compliesWithSLCUC: Yup.string().nullable(),
  compliesWithGoldenTriangle: Yup.string().nullable(),
  hasTrainingPrograms: Yup.string().nullable(),
  hasPracticalTestingPrograms: Yup.string().nullable(),
  isNccerAssessmentCenter: Yup.string().nullable(),
  trainingPrograms: Yup.array()
    .of(craftsmanValidateRow)
    .test({
      name: 'trainingProgramsTest',
      message: messages.required,
      test: function (value) {
        if (this.parent.hasTrainingPrograms !== enums.yesno.Yes) return true;
        return value.reduce(
          (acc, { name, ...fields }) => acc || Object.keys(fields).filter(key => fields[key]).length > 0,
          false,
        );
      },
    }),
  practicalTestingPrograms: Yup.array()
    .of(craftsmanValidateRow)
    .test({
      name: 'practicalTestingProgramsTest',
      message: messages.required,
      test: function (value) {
        if (this.parent.hasPracticalTestingPrograms !== enums.yesno.Yes) return true;
        return value.reduce(
          (acc, { name, ...fields }) => acc || Object.keys(fields).filter(key => fields[key]).length > 0,
          false,
        );
      },
    }),
});

export const addendumContractorCreate = Yup.object().shape({
  name: Yup.string()
    .max(255, messages.requiredNameMax255)
    .required(messages.required),
  fileStack: Yup.array()
    .of(
      Yup.object().shape({
        size: Yup.number().max(fileMaxSize, messages.file.bigFileSize),
      }),
    )
    .max(1, messages.file.required)
    .when('id', {
      is: id => !id,
      then: schema => schema.required(messages.required),
    }),
});

export const addendumContractorUpdate = Yup.object().shape({
  name: Yup.string()
    .required(messages.required)
    .max(255, messages.requiredNameMax255),
  fileStack: Yup.array()
    .of(
      Yup.object().shape({
        size: Yup.number().max(fileMaxSize, messages.file.bigFileSize),
      }),
    )
    .max(1, messages.file.required)
    .when('id', {
      is: id => !id,
      then: schema => schema.required(messages.required),
    }),
});

export const rejectReasonsSchema = Yup.object().shape({
  comment: Yup.string()
    .required(messages.required)
    .min(10, messages.requiredRejectReasonMin10)
    .max(255, messages.requiredRejectReasonMax255),
});

export const fileUploaderPopup = (expirationDateOption, completedOnOption, completedByOption) =>
  Yup.object().shape({
    files: Yup.array()
      .of(
        Yup.object().shape({
          description: Yup.string().required(messages.required),
          expirationDate: expirationDateOption
            ? Yup.mixed()
              .dateCheck(messages.invalidDate)
              .required(messages.required)
            : Yup.mixed()
              .dateCheck(messages.invalidDate)
              .nullable(),
          completedOn: completedOnOption
            ? Yup.mixed()
              .dateCheck(messages.invalidDate)
              .required(messages.required)
            : Yup.mixed()
              .dateCheck(messages.invalidDate)
              .nullable(),
          completedBy: completedByOption && Yup.string().required(messages.required),
        }),
      )
      .min(1, messages.required)
      .required(messages.required),
  });

export const uploadSizeFormSchema = Yup.object().shape({
  maxFileSizeMb: Yup.number()
    .max(20, messages.file.maxFileSize)
    .required(messages.required),
});

export const filterQuestions = Yup.object().shape({
  questionTitle: Yup.string()
    .max(100, messages.requiredQuestionTitleMax100)
    .min(3, messages.requiredQuestionMin3),
});

export const EMRSchema = Yup.object().shape({
  anniversaryDate: Yup.mixed()
    .dateCheck(messages.invalidDate)
    .nullable(),
});

export const BLSFormSchema = () => {
  const items = Object.keys(BLSIndustry).reduce(
    (acc, item) => ({
      ...acc,
      [item]: Yup.object().shape({
        value: Yup.number().required(messages.required),
      }),
    }),
    {},
  );

  return Yup.object().shape(items);
};

export const validateCompanySettings = Yup.object().shape({
  notificationEmails: Yup.array().of(Yup.string().email(messages.email)),
});

export const servicesPerformedSchema = Yup.object().shape({
  otherText: Yup.string().when('others', {
    is: others => others,
    then: schema => schema.min(3, messages.requiredServicesMax3).required(messages.required),
  }),
});

export const saveCompanyAdminPanel = Yup.object().shape({
  subscriptionExpirationDate: Yup.date().notRequired(),
  adminNotes: Yup.string().trim().notRequired(),
});
