import React from 'react';
import { Button, Grid, Box } from '@material-ui/core';
import { FastField, Formik } from 'formik';

import FormInput from '../../../../components/FormInput';
import PromptNotification from '../../../../components/PromptComponent';
import { createUserFormSchema } from '../../../../../utils/validate/shemas';
import useStyles from './styles';

const CreateNewUserForm = ({ setShowUserForm, handleSubmit }) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
      }}
      validationSchema={createUserFormSchema}
      onSubmit={handleSubmit}
    >
      {props => (
        <>
          <PromptNotification formStatus={props.dirty} />
          <form onSubmit={props.handleSubmit} noValidate>
            <Box borderColor="grey.300" borderTop={1} className={classes.formWrapper}>
              <Grid container spacing={3} alignItems="center" justify="space-between">
                <Grid item xs={3}>
                  <FastField
                    className={classes.inputRow}
                    textOnly
                    label="First Name"
                    name="firstName"
                    component={FormInput}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FastField
                    className={classes.inputRow}
                    textOnly
                    label="Last Name"
                    name="lastName"
                    component={FormInput}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FastField
                    className={classes.inputRow}
                    label="Email Address"
                    type="email"
                    name="email"
                    component={FormInput}
                  />
                </Grid>
                <Grid item xs={2}>
                  <FastField
                    className={classes.inputRow}
                    label="Phone Number"
                    type="tel"
                    name="phoneNumber"
                    component={FormInput}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Grid container spacing={3} justify="flex-end">
                    <Grid item xs={5}>
                      <Button size="medium" fullWidth variant="contained" color="primary" type="submit">
                        Invite
                      </Button>
                    </Grid>
                    <Grid item xs={5}>
                      <Button size="medium" fullWidth variant="contained" onClick={() => setShowUserForm(false)}>
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </form>
        </>
      )}
    </Formik>
  );
};

export default CreateNewUserForm;
