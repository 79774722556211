import { put, call, takeLatest } from 'redux-saga/effects';

import { currentCompany, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* create({ payload: { companyId, values, sectionKey } }) {
  try {
    const response = yield call(dataProvider.createOne, `companies/${companyId}/insurance-policies`, values);
    yield put({ type: currentCompany.insurancePolicy.create.end, payload: response.data });

    // update PQF document validation
    yield put({
      type: currentCompany.companyInfo.validation.start,
      payload: { companyId: companyId, validationKey: sectionKey },
    });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: currentCompany.insurancePolicy.create.error });
  }
}

export default function* createInsurancePolicySaga() {
  yield takeLatest(currentCompany.insurancePolicy.create.start, create);
}
