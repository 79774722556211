import React from 'react';
import { Grid, Divider, Typography } from '@material-ui/core';
import { FastField, Formik } from 'formik';

import { insurancePolicySchema } from '../../../utils/validate/shemas';
import InsuranceSubForm from './InsurancePolicySubForm';
import FormInput from '../../components/FormInput';
import FormRadio from '../../components/FormRadio';
import { yesnoOptions, enums } from '../../../utils/constants/formConstats';
import LoadingButton from '../../components/LoadingButton';
import PageLoader from '../../components/PageLoader';
import FormBody from '../../components/FormBody';
import {
  changeNullToEmptyString,
  formatDateForInitialValues,
  getValueForRadioButton,
} from '../../../utils/containersHelpers';
import useStyles from './styles';
import refineNumberString from '../../../utils/textFormaters/refineNumberString';
import PromptNotification from '../../components/PromptComponent';
import FileUploaderInput from '../../components/FileUploaderInput';
import AutomobileInsuranceSubForm from './AutomobileInsuranceSubForm';
import { fleetIndividualInsurance } from '../../../utils/constants/formConstats';
import { useCompanyInfo } from '../../../utils/effects/useCompanyInfo';

const FormRadioController = ({ field: { onChange }, field, parentobject, condition, ...props }) => {
  const { values, setFieldValue } = props.form;
  const keyValue = ['expirationDate'];
  const handleChange = params => {
    if (params.target.value === condition) {
      Object.keys(values[parentobject]).forEach(key =>
        setFieldValue(`${parentobject}.${key}`, keyValue.includes(key) ? null : ''),
      );
    }
    onChange(params);
  };
  return <FormRadio {...props} field={{ ...field, onChange: handleChange }} />;
};

const InsurancePolicyForm = ({ handleSubmit, policies, loading, success, error, allowUpdate, companyId }) => {
  const classes = useStyles();
  const { isBasicContractor } = useCompanyInfo(companyId);

  if (loading && !Object.keys(policies).length) {
    return <PageLoader />;
  }

  const setAmountOnSubmit = value => (value ? refineNumberString(`${value}`) : null);

  const onSubmit = values => {
    const { workersCompensation, generalLiability, vehicle } = values;
    const newValues = {
      ...values,
      workersCompensation: {
        ...workersCompensation,
        isSelfInsured: workersCompensation.isSelfInsured === enums.yesno.Yes,
        amount: setAmountOnSubmit(workersCompensation.amount),
      },
      generalLiability: {
        ...generalLiability,
        amount: setAmountOnSubmit(generalLiability.amount),
      },
      vehicle: {
        ...vehicle,
        hasVehicleInsurance: vehicle.hasVehicleInsurance === enums.yesno.Yes,
        amount: setAmountOnSubmit(vehicle.amount),
      },
    };

    handleSubmit(newValues);
  };

  const initialValues = formatDateForInitialValues(changeNullToEmptyString(policies));

  const { workersCompensation, vehicle } = initialValues;
  workersCompensation.isSelfInsured = getValueForRadioButton(workersCompensation.isSelfInsured);
  vehicle.hasVehicleInsurance = getValueForRadioButton(vehicle.hasVehicleInsurance);

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={insurancePolicySchema}
      onSubmit={onSubmit}
    >
      {props => {
        const {
          workersCompensation: { isSelfInsured },
          vehicle: { hasVehicleInsurance },
        } = props.values;
        return (
          <>
            <PromptNotification formStatus={props.dirty} />
            <FormBody loading={loading} disabled={!allowUpdate}>
              <Grid item lg={12}>
                <Grid container>
                  <Grid item lg={6} sm={6} className={classes.itemContainer}>
                    <Grid container>
                      <Grid item lg={12} sm={12}>
                        <Typography variant="h5" gutterBottom>
                          Company Contact for Insurance Information
                        </Typography>
                      </Grid>

                      <Grid item lg={8}>
                        {!isBasicContractor && (
                          <>
                            <FastField
                              className={classes.inputRow}
                              textOnly
                              label="First Name"
                              name="insurancePolicyContactInfo.firstName"
                              component={FormInput}
                            />
                            <FastField
                              className={classes.inputRow}
                              textOnly
                              label="Last Name"
                              name="insurancePolicyContactInfo.lastName"
                              component={FormInput}
                            />
                          </>
                        )}

                        <FastField
                          className={classes.inputRow}
                          label="Title"
                          name="insurancePolicyContactInfo.title"
                          component={FormInput}
                        />
                        <FastField
                          className={classes.inputRow}
                          label="Phone Number"
                          type="tel"
                          name="insurancePolicyContactInfo.phoneNumber"
                          component={FormInput}
                        />
                        {
                          // <FastField
                          //   className={classes.inputRow}
                          //   label="Fax"
                          //   type="tel"
                          //   name="insurancePolicyContactInfo.fax"
                          //   component={FormInput}
                          // />
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={6} sm={6} className={classes.itemContainer}>
                    <Grid container>
                      <Grid item lg={8}>
                        <InsuranceSubForm
                          title="General Liability Carrier"
                          names={{
                            name: 'generalLiability.name',
                            date: 'generalLiability.expirationDate',
                            amount: 'generalLiability.amount',
                          }}
                          {...props}
                        />
                      </Grid>
                      <Grid item lg={12} sm={12}>
                        <FastField
                          name="certificate"
                          component={FileUploaderInput}
                          label="Upload General Liability Insurance Certificate"
                          companyId={companyId}
                          expirationDate
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={12}>
                <Divider light className={classes.divider} />
              </Grid>
              <Grid item lg={12}>
                <Grid container>
                  <Grid item lg={12} sm={12} className={classes.itemContainer}>
                    <Grid container direction="row" alignItems="center" className={classes.formRadioContainer}>
                      <Typography variant="subtitle2">
                        Are you self insured for Workers' Compensation Insurance?
                      </Typography>
                      <FastField
                        name="workersCompensation.isSelfInsured"
                        component={FormRadioController}
                        options={yesnoOptions}
                        parentobject="workersCompensation"
                        condition={enums.yesno.Yes}
                      />
                    </Grid>
                  </Grid>
                  {isSelfInsured === enums.yesno.No && (
                    <>
                      <Grid item lg={6} sm={6} className={classes.itemContainer}>
                        <Grid container>
                          <Grid item lg={8}>
                            <InsuranceSubForm
                              title="Workers Compensation Carrier"
                              names={{
                                name: 'workersCompensation.name',
                                date: 'workersCompensation.expirationDate',
                                amount: 'workersCompensation.amount',
                              }}
                              {...props}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  <Grid item lg={12} sm={12} className={classes.itemContainer}>
                    <FastField
                      name="workerins"
                      component={FileUploaderInput}
                      label="Upload Workers Compensation Insurance"
                      companyId={companyId}
                      expirationDate
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={12}>
                <Divider light className={classes.divider} />
              </Grid>
              <Grid item lg={12}>
                <Grid container>
                  <Grid item lg={12} sm={12} className={classes.itemContainer}>
                    <Grid container direction="row" alignItems="center" className={classes.formRadioContainer}>
                      <Typography variant="subtitle2">Do you have company Automobile Insurance?</Typography>
                      <FastField
                        name="vehicle.hasVehicleInsurance"
                        options={yesnoOptions}
                        component={FormRadioController}
                        parentobject="vehicle"
                        condition={enums.yesno.No}
                      />
                    </Grid>
                  </Grid>
                  {hasVehicleInsurance === enums.yesno.Yes && (
                    <>
                      <Grid item lg={12} sm={12} className={classes.itemContainer}>
                        <Grid container direction="row" alignItems="center" className={classes.formRadioContainer}>
                          <Typography variant="subtitle2" gutterBottom>
                            Do you have fleet or individual vehicle Automobile Insurance?
                          </Typography>
                          <FastField
                            className={classes.inputRow}
                            label="Do you have fleet or individual vehicle Automobile Insurance?"
                            name="vehicle.vehicleInsuranceType"
                            options={fleetIndividualInsurance}
                            component={FormRadio}
                          />
                        </Grid>
                      </Grid>
                      <Grid item lg={12} sm={12} className={classes.itemContainer}>
                        <AutomobileInsuranceSubForm
                          companyId={companyId}
                          names={{
                            companyInsuranceName: 'vehicle.name',
                            expirationDate: 'vehicle.expirationDate',
                            amount: 'vehicle.amount',
                            autoinsur: 'autoinsur',
                          }}
                          {...props}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid item lg={12}>
                {allowUpdate && (
                  <LoadingButton
                    fixed
                    disabled={!props.dirty || !props.isValid}
                    text="save"
                    loading={loading}
                    success={success}
                    error={error}
                    onClick={props.handleSubmit}
                  />
                )}
              </Grid>
            </FormBody>
          </>
        );
      }}
    </Formik>
  );
};

export default InsurancePolicyForm;
