import React from 'react';
import { TableCell, TableSortLabel, TableHead as MaterialTableHead, TableRow } from '@material-ui/core';

import useStyles from './styles';
import { createEnum } from '../../../../../utils/actionTypesHelpers';

const actionsHeadCell = 'actions';

const headCellsEnum = createEnum({
  firstName: {
    id: 'firstName',
    label: 'First name',
    generalInfo: true,
  },
  lastName: {
    id: 'lastName',
    label: 'Last name',
    generalInfo: true,
  },
  emailAddress: {
    id: 'emailAddress',
    label: 'Email address',
    generalInfo: true,
  },
  phoneNumber: {
    id: 'phoneNumber',
    label: 'Phone number',
    generalInfo: true,
  },
  createDate: {
    id: 'createDate',
    label: 'Date created',
    generalInfo: true,
  },
  lastLoginDate: {
    id: 'lastLoginDate',
    label: 'Last login',
    generalInfo: false,
  },
  actions: {
    id: 'actions',
    label: 'Actions',
    generalInfo: false,
  },
});

const AllHeadCells = Object.keys(headCellsEnum).map(key => headCellsEnum[key]);

const getHeadCells = adminAccount => (adminAccount ? AllHeadCells : AllHeadCells.filter(cell => cell.generalInfo));

const UserTableHead = ({ order, orderBy, onRequestSort, isSafetyCouncilUserType }) => {
  const classes = useStyles();
  const createSortHandler = property => event => onRequestSort(event, property);

  return (
    <MaterialTableHead>
      <TableRow>
        {getHeadCells(isSafetyCouncilUserType).map(headCell => (
          <TableCell
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            align={headCell.id === actionsHeadCell ? 'center' : 'left'}
            className={classes[headCell.id]}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={headCell.id !== 'actions' ? createSortHandler(headCell.id) : undefined}
              hideSortIcon={headCell.id === 'actions'}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </MaterialTableHead>
  );
};

export default UserTableHead;
