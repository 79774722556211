import React from 'react';
import { Grid, TableCell, Typography, IconButton } from '@material-ui/core';
import { FastField, Formik } from 'formik';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';

import FormInput from '../../../../../components/FormInput';
import Ellipsis from '../../../../../components/Ellipsis';
import useStyles from './styles';
import { editUserFormSchema } from '../../../../../../utils/validate/shemas';
import formatDate from '../../../../../../utils/textFormaters/date';
import TooltipWrapper from '../../../../../components/TooltipWrapper';

const EditUserForm = ({ setUserToEdit, onConfirmEditUser, values }) => {
  const classes = useStyles();
  const { emailAddress, createDate, lastLoginDate } = values;
  const initialValues = { ...values };

  return (
    <Formik initialValues={initialValues} validationSchema={editUserFormSchema} onSubmit={onConfirmEditUser}>
      {props => {
        return (
          <>
            <TableCell component="th" scope="row" align="left">
              <FastField
                className={classes.inputRow}
                textOnly
                label="First Name"
                name="firstName"
                component={FormInput}
              />
            </TableCell>
            <TableCell align="left">
              <FastField
                className={classes.inputRow}
                textOnly
                label="Last Name"
                name="lastName"
                component={FormInput}
              />
            </TableCell>
            <TableCell align="left" className={classes.tableCell}>
              <Ellipsis title={emailAddress} />
            </TableCell>
            <TableCell align="left">
              <FastField
                className={classes.inputRow}
                label="Phone Number"
                type="tel"
                name="phoneNumber"
                component={FormInput}
              />
            </TableCell>
            <TableCell align="left" className={classes.tableCell}>
              {formatDate(createDate)}
            </TableCell>
            <TableCell align="left">
              {lastLoginDate ? (
                formatDate(lastLoginDate)
              ) : (
                <Typography gutterBottom variant="body1">
                  Not logged in
                </Typography>
              )}
            </TableCell>
            <TableCell className={classes.tableCell}>
              <Grid container justify="space-around">
                <Grid item className={classes.saveButton}>
                  <TooltipWrapper tooltip title="Save">
                    <IconButton size="small" aria-label="Save" color="primary" onClick={() => props.handleSubmit()}>
                      <SaveIcon />
                    </IconButton>
                  </TooltipWrapper>
                </Grid>
                <Grid item>
                  <TooltipWrapper tooltip title="Cancel">
                    <IconButton size="small" aria-label="Cancel" color="default" onClick={() => setUserToEdit(false)}>
                      <CancelIcon />
                    </IconButton>
                  </TooltipWrapper>
                </Grid>
              </Grid>
            </TableCell>
          </>
        );
      }}
    </Formik>
  );
};

export default EditUserForm;
