import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { FastField, Formik } from 'formik';

import useStyles from './styles';
import { uploadSizeFormSchema } from '../../../utils/validate/shemas';
import FormNumberInput from '../../components/FormNumberInput';
import FormSwitch from '../../components/FormSwitch';
import PageLoader from '../../components/PageLoader';
import FormBody from '../../components/FormBody';
import LoadingButton from '../../components/LoadingButton';
import PromptNotification from '../../components/PromptComponent';
import MultiselectDropdown from '../../components/MultiselectDropdown';

const SettingsForm = ({ loading, users, data, handleUpdateSize, success, error }) => {
  const classes = useStyles();

  if (!Object.keys(data).length || !users.length) return <PageLoader />;

  const userOptions = users.map(user => ({ id: user.emailAddress, name: user.emailAddress }));

  return (
    <Formik
      initialValues={{
        maxFileSizeMb: data.maxFileSizeMb || 0,
        promptConfirm: data.promptConfirm,
        notificationEmails: data.notificationEmails,
        notificationEmailsWorkingHours: data.notificationEmailsWorkingHours,
      }}
      enableReinitialize
      onSubmit={handleUpdateSize}
      validationSchema={uploadSizeFormSchema}
    >
      {props => {
        return (
          <>
            <PromptNotification formStatus={props.dirty} />
            <FormBody loading={loading}>
              <Grid item xs={12}>
                <Grid container justify="center" alignItems="flex-start" spacing={1}>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Grid container direction="row" alignItems="flex-start" justify="space-between">
                          <Grid item xs={7} className={classes.title}>
                            <Typography variant="h5" gutterBottom>
                              Supporting Document Upload Size (in Mb):
                            </Typography>
                          </Grid>
                          <Grid item xs={4} className={classes.itemContainer}>
                            <FastField
                              label="Size"
                              name="maxFileSizeMb"
                              component={FormNumberInput}
                              maskType="fromOne"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Grid container alignItems="center" justify="space-between" className={classes.bottomContainer}>
                          <Grid item xs={6}>
                            <Typography variant="h5" gutterBottom>
                              Notify if data is not saved:
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Grid container spacing={2} alignItems="center" justify="center">
                              <Grid item>
                                <Typography variant="body1" gutterBottom>
                                  Off
                                </Typography>
                              </Grid>
                              <Grid item className={classes.label}>
                                <FastField name="promptConfirm" component={FormSwitch} />
                              </Grid>
                              <Grid item>
                                <Typography variant="body1" gutterBottom>
                                  On
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container className={classes.bottomContainer}>
                      <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                          Send notification for email:
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FastField
                          label="Notification emails"
                          name="notificationEmails"
                          options={userOptions}
                          component={MultiselectDropdown}
                          multiple
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container className={classes.bottomContainer}>
                      <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                          Send notification email about working hours:
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <FastField
                          label="Notification emails"
                          name="notificationEmailsWorkingHours"
                          options={userOptions}
                          component={MultiselectDropdown}
                          multiple
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <LoadingButton
                skipCheckPQF
                disabled={!props.dirty || !props.isValid}
                text="save"
                loading={loading}
                success={success}
                error={error}
                onClick={props.handleSubmit}
              />
            </FormBody>
          </>
        );
      }}
    </Formik>
  );
};

export default SettingsForm;
