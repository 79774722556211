import React, { useState } from 'react';
import { Button } from '@material-ui/core';

import ListOwners from './ListOwners';
import GradeModal from '../GradeModal';

const OwnerGrading = ({ listOwners, ownerGrading, getOwnerGrading, handleDownloadOwnerGrade, companyId, userType }) => {
  const [openModal, setOpenModal] = useState(false);
  const [openGradingModal, setOpenGradingModal] = useState(null);

  const handleGradingModal = id => {
    getOwnerGrading(id);
    setOpenModal(false);
    setOpenGradingModal(id);
  };

  return (
    <>
      <Button variant="contained" color="primary" fullWidth size="small" onClick={() => setOpenModal(true)}>
        Owner Grading
      </Button>
      <ListOwners
        open={openModal}
        handleClose={() => setOpenModal(false)}
        openGradingModal={handleGradingModal}
        list={listOwners}
      />
      <GradeModal
        open={!!openGradingModal}
        handleClose={() => {
          setOpenGradingModal(null);
          setOpenModal(true);
        }}
        loadingData={ownerGrading.loading}
        list={ownerGrading.list}
        loading={ownerGrading.loadingScorecard}
        handleDownloadGrade={score => handleDownloadOwnerGrade(openGradingModal, score)}
        companyId={companyId}
        userType={userType}
      />
    </>
  );
};

export default OwnerGrading;
