import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  percentage: {
    fontSize: theme.typography.h5.fontSize,
  },
  container: {
    padding: '0 !important',
    height: 'auto',
  },
  input: {
    width: '100% !important',
    border: 'none !important',
    paddingTop: '10.5px !important',
    paddingBottom: '10.5px !important',

    '&:focus': {
      boxShadow: 'none !important',
    },
  },
}));

export default useStyles;
