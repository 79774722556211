import React from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import ReferenseEditForm from '../EditForm';
import ModalDialog from '../../../components/ModalConfirmation';
import useStyles from './styles';
import TooltipWrapper from '../../../components/TooltipWrapper';
import { addDollarCommaSigns } from '../../../../utils/containersHelpers';

const ReferenceTable = ({
  references,
  setIsModalOpen,
  setItemToDelete,
  allowUpdate,
  setItemToEdit,
  onConfirmEditReference,
  onConfirmDeleteReference,
  itemToEdit,
  isModalOpen,
  loadingOnTable,
}) => {
  const classes = useStyles();

  return (
    <TableContainer component={Paper} className={classes.tableWrapper}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell colSpan={3} className={classes.title} align="center">
              Customer Name
            </TableCell>
            <TableCell colSpan={3} align="center">
              Address
            </TableCell>
            <TableCell colSpan={3} align="center">
              Type Of Work
            </TableCell>
            <TableCell colSpan={2} align="center">
              Job Size
            </TableCell>
            <TableCell colSpan={3} align="center">
              Contact Person
            </TableCell>
            <TableCell colSpan={3} align="center">
              Phone Number
            </TableCell>
            <TableCell align="center" />
          </TableRow>
        </TableHead>
        <TableBody>
          {references && references.length > 0 ? (
            references.map((row, index) => {
              return row.id === itemToEdit ? (
                <ReferenseEditForm
                  key={index}
                  values={row}
                  onConfirmEditReference={onConfirmEditReference}
                  setItemToEdit={setItemToEdit}
                  loadingOnTable={loadingOnTable}
                />
              ) : (
                <TableRow key={index}>
                  <TableCell colSpan={3} scope="row" align="center">
                    <Grid container wrap="nowrap">
                      <Grid item xs zeroMinWidth>
                        <Typography noWrap>{row.customerName}</Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell colSpan={3} scope="column" align="center">
                    <Grid container wrap="nowrap">
                      <Grid item xs zeroMinWidth>
                        <Typography noWrap>{row.contactInfo.streetAddress.city}</Typography>
                      </Grid>
                    </Grid>
                    <Typography>{row.contactInfo.streetAddress.state}</Typography>
                    <Typography>{row.contactInfo.streetAddress.zip}</Typography>
                  </TableCell>
                  <TableCell colSpan={3} align="center">
                    <Grid container wrap="nowrap" alignItems="center">
                      <Grid item xs zeroMinWidth>
                        <Typography noWrap>{row.typeOfWork}</Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell colSpan={2} align="center">
                    {addDollarCommaSigns(row.jobSize || 0)}
                  </TableCell>
                  <TableCell colSpan={3} scope="row" align="center">
                    <Grid container wrap="nowrap">
                      <Grid item xs zeroMinWidth>
                        <Typography noWrap>
                          {`${row.contactInfo.contactPersonFirstName} ${row.contactInfo.contactPersonLastName}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell colSpan={3} align="center">
                    {row.contactInfo.phoneNumber && row.contactInfo.phoneNumber}
                  </TableCell>
                  {allowUpdate && (
                    <TableCell align="center">
                      <Grid container justify="center" alignItems="flex-end">
                        <TooltipWrapper tooltip title="Edit">
                          <IconButton
                            aria-label="edit"
                            color="primary"
                            className={classes.buttons}
                            onClick={() => setItemToEdit(row.id)}
                          >
                            <EditIcon />
                          </IconButton>
                        </TooltipWrapper>
                        <TooltipWrapper tooltip title="Delete">
                          <IconButton
                            className={classes.buttons}
                            onClick={() => {
                              setIsModalOpen(true);
                              setItemToDelete(row.id);
                            }}
                          >
                            <DeleteIcon color="error" />
                          </IconButton>
                        </TooltipWrapper>
                      </Grid>
                    </TableCell>
                  )}
                  {isModalOpen && (
                    <ModalDialog
                      title="Are you sure you want to delete this reference? "
                      isModalOpen={isModalOpen}
                      onCancel={() => setIsModalOpen(false)}
                      onConfirm={onConfirmDeleteReference}
                    />
                  )}
                </TableRow>
              );
            })
          ) : (
            <TableRow>
              <TableCell colSpan={18} align="center">
                You don't have any references yet
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReferenceTable;
