import { call, put, takeLatest } from 'redux-saga/effects';

import { currentCompany, notification } from '../../../actionTypes';
import dataProvider from '../../../store/dataProvider';

export function* exportXlsx({ payload: { companyId, ...restParams } }) {
  try {
    yield call(dataProvider.getOnly, `companies/${companyId}/grade/xlsx`, restParams);

    yield put({ type: currentCompany.questions.export.end, payload: {} });
  } catch (e) {
    yield put({ type: notification.add, payload: { error: e.message } });
    yield put({ type: currentCompany.questions.export.error, payload: { error: e.message } });
  }
}

export default function* exportXlsxSaga() {
  yield takeLatest(currentCompany.questions.export.start, exportXlsx);
}
