import React from 'react';
import PhoneInput from 'react-phone-input-2';

import useStyles from './styles.js';

const PhoneFlagInput = ({ onChange, inputRef, setFieldValue, ...field }) => {
  const classes = useStyles();

  const countryDataFormatKey = `${field.name}_countryDataFormat_`;
  const countryDataDialCodeKey = `${field.name}_countryDataDialCode_`;

  return (
    <PhoneInput
      {...field}
      specialLabel=""
      placeholder=""
      country="us"
      onMount={async (_value, countryData, formattedValue) => {
        sessionStorage.setItem(countryDataFormatKey, countryData.format);
        sessionStorage.setItem(countryDataDialCodeKey, countryData.dialCode)

        await setFieldValue(field.name, formattedValue);
      }}
      onChange={async (_value, countryData, e, formattedValue) => {
        sessionStorage.setItem(countryDataFormatKey, countryData.format)
        sessionStorage.setItem(countryDataDialCodeKey, countryData.dialCode)

        await setFieldValue(field.name, formattedValue);

        e.persist();

        onChange(e);
      }}
      inputProps={{ ref: inputRef, name: field.name }}
      containerClass={classes.container}
      inputClass={classes.input}
    />
  );
};

export default PhoneFlagInput;
