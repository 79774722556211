import React from 'react';
import { CircularProgress, Grid, TableCell, TableRow } from '@material-ui/core';
import { FastField, Formik } from 'formik';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';

import { referencesFormSchema } from '../../../../utils/validate/shemas';
import FormSelect from '../../../components/FormSelect';
import FormInput from '../../../components/FormInput';
import FormNumberInput from '../../../components/FormNumberInput';
import usaStates from '../../../../utils/constants/usaStates';
import { changeNullToEmptyString, changeZeroToEmptyString } from '../../../../utils/containersHelpers';
import useStyles from './styles';
import PromptNotification from '../../../components/PromptComponent';
import TooltipWrapper from '../../../components/TooltipWrapper';

const ReferencesEditForm = ({ values, loadingOnTable, onConfirmEditReference, setItemToEdit }) => {
  const classes = useStyles();

  const initialValues = changeZeroToEmptyString(changeNullToEmptyString(values), ['jobSize']);

  return (
    <Formik initialValues={initialValues} validationSchema={referencesFormSchema} onSubmit={onConfirmEditReference}>
      {props => {
        return (
          <TableRow>
            <TableCell colSpan={3} align="center">
              <PromptNotification formStatus={props.dirty} />
              <Grid container alignItems="center">
                <FastField
                  className={classes.inputRow}
                  name="customerName"
                  label="Customer Name"
                  component={FormInput}
                />
              </Grid>
            </TableCell>
            <TableCell colSpan={3} align="center">
              <Grid container direction="column" alignItems="center">
                <FastField
                  className={classes.inputRow}
                  name="contactInfo.streetAddress.city"
                  label="City"
                  component={FormInput}
                />
                <FastField
                  className={classes.inputRow}
                  name="contactInfo.streetAddress.state"
                  label="State"
                  id="state"
                  options={usaStates}
                  component={FormSelect}
                />
                <FastField
                  className={classes.inputRow}
                  label="Zip"
                  name="contactInfo.streetAddress.zip"
                  maskType="zip"
                  component={FormNumberInput}
                />
              </Grid>
            </TableCell>
            <TableCell colSpan={3} align="center">
              <Grid container alignItems="center">
                <FastField className={classes.inputRow} name="typeOfWork" label="Type Of Work" component={FormInput} />
              </Grid>
            </TableCell>
            <TableCell colSpan={2} align="center">
              <Grid container alignItems="center">
                <FastField
                  name="jobSize"
                  label="Job Size"
                  maskType="dollarMaskTenNumbers"
                  component={FormNumberInput}
                />
              </Grid>
            </TableCell>
            <TableCell colSpan={3} align="center">
              <Grid container direction="column" alignItems="center">
                <FastField
                  className={classes.inputRow}
                  textOnly
                  name="contactInfo.contactPersonFirstName"
                  label="First Name"
                  component={FormInput}
                />
                <FastField
                  className={classes.inputRow}
                  textOnly
                  name="contactInfo.contactPersonLastName"
                  label="Last Name"
                  component={FormInput}
                />
              </Grid>
            </TableCell>
            <TableCell colSpan={3} align="center">
              <Grid container direction="column" alignItems="center">
                <FastField type="tel" label="Phone Number" name="contactInfo.phoneNumber" component={FormInput} />
              </Grid>
            </TableCell>
            <TableCell align="center">
              <Grid container justify="center" alignItems="center">
                {loadingOnTable ? (
                  <CircularProgress />
                ) : (
                  <>
                    <TooltipWrapper tooltip title="Done">
                      <IconButton aria-label="Done" color="primary" onClick={() => props.handleSubmit()}>
                        <DoneIcon />
                      </IconButton>
                    </TooltipWrapper>
                    <TooltipWrapper tooltip title="Cancel">
                      <IconButton aria-label="Cancel" color="default" onClick={() => setItemToEdit(null)}>
                        <CancelIcon />
                      </IconButton>
                    </TooltipWrapper>
                  </>
                )}
              </Grid>
            </TableCell>
          </TableRow>
        );
      }}
    </Formik>
  );
};

export default ReferencesEditForm;
