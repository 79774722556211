import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';

import LoadingButton from '../../components/LoadingButton';
import Months from './Months';
import useStyles from './styles';
import PromptNotification from '../../components/PromptComponent';
import { getInitialValues } from './form';
import FormBody from '../../components/FormBody';
import { workingHours } from '../../../actionTypes';
import Toolbar from './Toolbar';
import WorkingHourNotesForm from '../WorkingHourNotesForm';

const WorkingHoursForm = ({
  handleSave,
  ownerId,
  readOnly,
  withSearchByOwner,
  ownerList,
  contractorId,
  withExportWorkingHours,
  withNotes,
  userType,
}) => {
  const [selectedYear, setSelectedYear] = useState(() => new Date().getFullYear());
  const [selectedOwner, setSelectedOwner] = useState(() => ownerId || null);

  const { workingHours: workingHoursData, loading, success, error, exporting } = useSelector(
    store => store.workingHours.list,
  );

  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    if (selectedOwner) {
      dispatch({
        type: workingHours.list.start,
        payload: { contractorId, ownerId: selectedOwner },
      });
    }
  }, [contractorId, selectedOwner, dispatch]);

  const handleExportWorkingHours = () =>
    dispatch({
      type: workingHours.exportWorkingHours.start,
      payload: {
        userType,
        contractorCompanyId: contractorId,
        ownerCompanyId: selectedOwner,
      },
    });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Formik initialValues={getInitialValues(workingHoursData, selectedYear)} onSubmit={handleSave} enableReinitialize>
          {props => {
            return (
              <Form className={classes.container}>
                {readOnly ? null : <PromptNotification formStatus={props.dirty} />}
                <Toolbar
                  withSearchByOwner={withSearchByOwner}
                  selectedOwner={selectedOwner}
                  setSelectedOwner={setSelectedOwner}
                  selectedYear={selectedYear}
                  setSelectedYear={setSelectedYear}
                  ownerList={ownerList}
                  workingHoursList={workingHoursData}
                />
                <FormBody spacing={0} loading={loading} disabled={readOnly}>
                  <Months />
                  {!readOnly && (
                    <LoadingButton
                      disabled={!props.dirty || !props.isValid}
                      text="Save"
                      loading={loading}
                      success={success}
                      error={error}
                      onClick={props.handleSubmit}
                      skipCheckPQF
                    />
                  )}
                </FormBody>
                <div className={classes.actions}>
                  {withExportWorkingHours && (
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={exporting ? <CircularProgress size={20} /> : <GetAppIcon />}
                      onClick={handleExportWorkingHours}
                      disabled={exporting}
                    >
                      Export
                    </Button>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </Grid>
      {withNotes && (
        <Grid item xs={12}>
          <WorkingHourNotesForm
            selectedYear={selectedYear}
            ownerId={selectedOwner}
            contractorId={contractorId}
            workingHoursData={workingHoursData}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default WorkingHoursForm;
