import React from 'react';

import NotificationContainer from './NotificationContainer';
import ValidationErrorNotification from './types/validationError';
import InProgressNotification from './types/inProgress';
import PendingReviewNotification from './types/pendingReview';
import { userTypes } from '../../../../utils/constants';

const PendingReview = 'PendingReview';

const Notification = ({
  sections,
  isGenerationStarted,
  companyType,
  conditions,
  creation,
  handleSubmitDocument,
  unofficialGrade,
  companyId,
}) => {
  return (
    <>
      <NotificationContainer
        open={isGenerationStarted && companyType === userTypes.Contractor && sections.length > 0}
        error
      >
        <ValidationErrorNotification />
      </NotificationContainer>

      <NotificationContainer
        open={isGenerationStarted && companyType === userTypes.Contractor && !sections.length}
        success
      >
        <InProgressNotification
          {...unofficialGrade}
          creation={creation}
          handleSubmitDocument={handleSubmitDocument}
          companyId={companyId}
          userType={companyType}
        />
      </NotificationContainer>

      <NotificationContainer
        open={companyType === userTypes.Contractor && conditions.currentPqfDocumentStatus === PendingReview}
        warning
      >
        <PendingReviewNotification />
      </NotificationContainer>
    </>
  );
};

export default Notification;
