import { all, fork } from 'redux-saga/effects';

import auth from './auth';
import company from './company';
import currentCompany from './currentCompany';
import notification from './notification';
import yesNoQuestionsAll from './yesNoQuestionsAll';
import pages from './pages';
import file from './file';
import filePsmAudit from './filePsmAudit';
import archive from './archive';
import settings from './settings';
import headerNotifications from './header/notifications';
import review from './review';
import addendumFile from './addendumFile';
import workingHours from './workingHours';
import workingHourNotes from './workingHourNotes';

const sagas = [
  ...auth,
  ...company,
  ...currentCompany,
  ...notification,
  ...yesNoQuestionsAll,
  ...pages,
  ...file,
  ...filePsmAudit,
  ...archive,
  ...settings,
  ...headerNotifications,
  ...review,
  ...addendumFile,
  ...workingHours,
  ...workingHourNotes
];

export default function* rootSaga() {
  yield all(sagas.map(saga => fork(saga)));
}
