import React from 'react';
import { Grid } from '@material-ui/core';
import { Formik, FastField } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import FormInput from '../../components/FormInput';
import LoadingButton from '../../components/LoadingButton';
import useStyles from '../../components/Icons/styles';
import PromptNotification from '../../components/PromptComponent';
import { getInitialValues } from './form';
import FormBody from '../../components/FormBody';
import { workingHourNotes } from '../../../actionTypes';

const WorkingHourNotesForm = ({
  selectedYear,
  ownerId,
  workingHoursData,
  contractorId,
}) => {
  const { loading, success, error } = useSelector(
    store => store.workingHourNotes.update,
  );

  const dispatch = useDispatch();
  const classes = useStyles();

  const handleSave = values => {
    dispatch({
      type: workingHourNotes.update.start,
      payload: { contractorId, ownerId, values },
    });
  };

  return (
    <Formik initialValues={getInitialValues(workingHoursData, selectedYear)} onSubmit={handleSave} enableReinitialize>
      {props => {
        return (
          <>
            <PromptNotification formStatus={props.dirty} />

            <FormBody spacing={1} loading={loading}>
              <Grid item xs={12}>
                <Grid container>
                  <FastField
                    className={classes.textArea}
                    name="notes"
                    rows={10}
                    multiline
                    placeholder="Notes"
                    component={FormInput}
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} alignContent="flex-end">
                <LoadingButton
                  disabled={!props.dirty || !props.isValid}
                  text="Save Notes"
                  loading={loading}
                  success={success}
                  error={error}
                  onClick={props.handleSubmit}
                  skipCheckPQF
                />
              </Grid>
            </FormBody>
          </>
        );
      }}
    </Formik>
  );
};

export default WorkingHourNotesForm;
